<template>
  <div>
    <ek-table
      @delete-selected="deleteAccounts"
      :items="AccountsList"
      :columns="cols"
      @details="goToDetails"
      deleteBtn
    >
      <template slot="items.dateCreated" slot-scope="{ value }">
        {{ value ? new Date(value).toLocaleDateString() : '' }}
      </template>
      <template slot="items.isBlocked" slot-scope="{ value }">
        <b-badge pill :variant="value ? 'danger' : 'success'">{{
          value ? "محظور" : "فعال"
        }}</b-badge>
      </template>
    </ek-table>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  data: () => ({
    cols: [
      {
        label: "الاسم",
        field: "name",
      },
      {
        label: "البريد الإلكتروني",
        field: "email",
      },
      {
        label: "رقم الهاتف",
        field: "phoneNumber",
      },
      {
        label: "تاريخ الانشاء",
        field: "dateCreated",
      },
      {
        label: "حالة الحساب",
        field: "isBlocked",
      },
      {
        label: "التفاصيل",
        field: "details",
      },
    ],
  }),
  computed: {
    ...mapState({
      users: ({ accounts }) => accounts.users,
    }),
    ...mapGetters(["AccountsList"]),
  },
  created() {
    this.$store.dispatch("getDashUsers");
  },
  methods: {
    goToDetails({ row }) {
      this.$router.push(`accounts/${row.id}`);
    },
    deleteAccounts(e) {
      this.$store.dispatch("deleteAccounts", e);
    },
  },
};
</script>
